class form_handling {
    constructor(){

        const fields = document.querySelectorAll('.block.form_wuchshuelle .fieldgroup .column:first-of-type ul li')
        let summued_up_amount = 0

        for(var i = 0; i < fields.length; i++){

            const field = fields[i]
            let input = field.querySelector("input")

            input.addEventListener("input",(event) => {

                summued_up_amount = 0

                for(var v = 0; v < fields.length; v++){

                    const value = fields[v]

                    let field_value = value.querySelector("input").value
                    summued_up_amount = summued_up_amount + parseInt(field_value)

                }

                let additional_value = document.getElementById("sonderlaenge_menge").value
                summued_up_amount = summued_up_amount + parseInt(additional_value)

                document.getElementById("kabelbinder_menge").value = summued_up_amount * 2

            })

        }

        if(document.getElementById("sonderlaenge_menge")){

            document.getElementById("sonderlaenge_menge").addEventListener("input",(event) => {

                summued_up_amount = 0

                    for(var v = 0; v < fields.length; v++){

                        const value = fields[v]

                        let field_value = value.querySelector("input").value
                        summued_up_amount = summued_up_amount + parseInt(field_value)

                    }

                    let additional_value = document.getElementById("sonderlaenge_menge").value
                    summued_up_amount = summued_up_amount + parseInt(additional_value)

                    document.getElementById("kabelbinder_menge").value = summued_up_amount * 2

            })

        }

    }
}

export default form_handling
